@font-face{
  font-family:'Titling';
  src:url("//bespoke.mse.jhu.edu/webfonts/Titling-Gothic-Medium.eot");
  src:url("//bespoke.mse.jhu.edu/webfonts/Titling-Gothic-Medium.eot?#iefix") format("embedded-opentype"),
    url("//bespoke.mse.jhu.edu/webfonts/Titling-Gothic-Medium.woff") format("woff"),
    url("//bespoke.mse.jhu.edu/webfonts/Titling-Gothic-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face{
  font-family:'Gentona';
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-Book.eot");
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-Book.eot?#iefix") format("embedded-opentype"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-Book.woff") format("woff"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-Book.ttf") format("truetype");
  font-display: swap;
}

@font-face{
  font-family:'Gentona-Bold';
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-Bold.eot");
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-Bold.eot?#iefix") format("embedded-opentype"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-Bold.woff") format("woff"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face{
  font-family:'Gentona-Medium';
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-Medium.eot");
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-Medium.eot?#iefix") format("embedded-opentype"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-Medium.woff") format("woff"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face{
  font-family:'Gentona-Italic';
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-BookItalic.eot");
  src:url("//bespoke.mse.jhu.edu/webfonts/Gentona-BookItalic.eot?#iefix") format("embedded-opentype"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-BookItalic.woff") format("woff"),
    url("//bespoke.mse.jhu.edu/webfonts/Gentona-BookItalic.ttf") format("truetype");
  font-display: swap;
}

#separationGlyph {
  padding-top: 7px;
}

.remoteLink:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
}

::-webkit-input-placeholder {
	 color: #767676;
	 opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
	 color: #767676;
	 opacity: 1;
}

::-moz-placeholder {  /* Firefox 19+ */
	 color: #767676;
	 opacity: 1;
}

:-ms-input-placeholder {
	 color: #767676;
	 opacity: 1;
}

*, ::after, ::before {
    box-sizing: border-box;
    /* box-sizing: content-box; */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Gentona", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, #main-title, .resourceDetailTitle, #tagDetailTitle {
  font-family: "Titling", sans-serif;
  font-size: 31px;
  text-transform: uppercase;
  color: #002D72;
  margin-top: 25px;
}

h2 {
  font-size: 31px;
  font-family: "Gentona";
  text-transform: none;
}

h3 {
  font-size: 21px;
}

a:link, a:visited {
  color: #002D72
}

.row {
  align-items: flex-end;
  display: flex;
  justify-content: left;
}

#top-logo {
width: 200px;  /* was 325 */
vertical-align:10%;
}

#main-title {
  text-align: center;
}

.nav {
  flex-wrap: nowrap;
}

.jhu-first-nav {
    font-family: "Gentona-Medium", sans-serif;
    background-color: #002D72;
    font-size: 15px;
    color: white;
    text-align: left;
    width: 100%;
    font-weight: bold;
    padding-top: 3px;
    padding-bottom: 3px;
}

a.jhu-first-nav {
    color: white;
}

a.jhu-first-nav:hover {
    text-decoration: underline;
}

.jhu-second-nav {
  margin-top: 10px;
  font-family: "Gentona-Bold", sans-serif;
}

.jhu-second-nav, .jhu-footer {
  font-size: 15px;
  color: #002D72;
  text-align: left;
}

a.jhu-second-nav:hover, a.jhu-footer-link:hover, a.itemTitle {
    text-decoration: underline;
}

.jhu-footer, #azlist {
  margin-top: 25px;
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}

#footer-logo {
  width: 250px;
  background-color: transparent;
}

#jhutext {
  font-family: "Gentona-Italic", sans-serif;
  margin-bottom: 15px;
}

.count {
  margin-top: 15px;
  margin-bottom: 15px;
}

#SearchBox {
  font-family: "Gentona", sans-serif;
  padding-top: 25px;
  padding-bottom: 15px;
}

span.itemTitle {
  font-family: "Gentona", sans-serif;
  font-size: 20px;
}

.itemAccessRestrictions {
  font-size: 12px;
}

@media screen and (max-width: 1100px) {
  .jhu-first-nav, .jhu-second-nav-links {
    display: none;
  }
}

.App {
  text-align: left;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

